import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { media } from '../utils/style-utils';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import LayoutSection from '../components/LayoutSection';

const MainHeader = styled.header`
  text-align: center;
  margin-bottom: 3rem;

  h1 {
    display: inline-block;
    padding-bottom: 2rem;
    /* border-bottom: 1px solid var(--grey-3); */
  }
`;

const MainContent = styled.section`
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;

  aside {
    flex: 1 0 100%;
    order: -1;
    ${media.desktop`
      flex: 1;
      order: 0;
    `}
  }

  article {
    flex: 1 0 100%;
    ${media.desktop`
      flex: 0 0 45%;
    `}

    h3 {
      margin: 0;
    }
  }
`;

const Publications = styled.ul`
  font-weight: 600;
  line-height: 1.5;
  font-size: 1.5rem;
  padding: 2rem 0;
  margin-top: -3rem;
`;

const SubContent = styled.section`
  border-top: 1px solid var(--grey-2);
  padding: 2rem;
  text-align: center;
  ${media.desktop`
      padding: 1% 0 3% 0;
    `}

  h2 {
    text-align: center;
    /* border-bottom: 1px solid var(--grey-3); */
    padding-bottom: 2rem;
    display: inline-block;

    span {
      font-weight: 600;
    }
  }
`;

const Reviews = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
  justify-content: center;

  li {
    ${media.desktop`
      flex: 0 0 350px;
    `}

    &:not(:last-child) {
      ${media.desktop`
      padding-right: 2rem;
      border-right: 1px dotted var(--grey-3b);
    `}
    }
  }

  p {
    margin: 0.15em 0;
    text-align: left;
  }

  cite {
    font-weight: 600;
    display: block;
    text-align: left;
  }
`;

const BuyButton = styled(Link)`
  padding: 0.8rem 2rem;
  text-align: center;
  border: 1px solid var(--black);
  font-family: var(--secondary-font);
  border-radius: 50px;
  font-size: 1.6rem;
  font-weight: 600;
  display: block;
  margin-bottom: 2rem;

  ${media.desktop`
      display: inline-block;
    `}
`;

export default function SingleBookPage({ data: { book } }) {
  return (
    <div>
      <SEO title={book.name} image={book.image?.asset?.fluid?.src} />
      <Layout>
        <LayoutSection isWhite>
          <MainHeader>
            <h1>{book.name}</h1>
            <Publications>
              {book.publications.map((publication) => (
                <li key={publication.id}>{publication}</li>
              ))}
            </Publications>
          </MainHeader>
          <MainContent>
            <article>
              <h3>Description</h3>
              <p>{book.bookDescription}</p>
              <BuyButton to={book.link}>Buy on Amazon</BuyButton>
            </article>

            <aside>
              <Img fluid={book.imageLarge?.asset?.fluid} alt="book.name" />
            </aside>
          </MainContent>
        </LayoutSection>
        <SubContent>
          <LayoutSection>
            <h2>
              <span>Reviews of:</span> {book.name}
            </h2>
            <Reviews>
              {book.reviews.map((review) => (
                <li key={review.id}>
                  <p>{review.reviewText}</p>
                  <cite>{review.source}</cite>
                </li>
              ))}
            </Reviews>
          </LayoutSection>
        </SubContent>
      </Layout>
    </div>
  );
}

export const query = graphql`
  query($slug: String!) {
    book: sanityBook(slug: { current: { eq: $slug } }) {
      name
      id
      imageLarge {
        asset {
          fluid(maxWidth: 800) {
            ...GatsbySanityImageFluid
          }
        }
      }
      image {
        asset {
          fluid(maxWidth: 800) {
            ...GatsbySanityImageFluid
          }
        }
      }
      publications
      reviews {
        source
        reviewText
      }
      bookDescription
      link
    }
  }
`;
